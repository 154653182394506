/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div className="page-holder">
        <Header
        // siteTitle={data.site.siteMetadata.title}
        />
        <div>
          <main>{children}</main>
{/* 
          <h3 className="italic text-sm mb-5 font-bold text-base">Hinnat:</h3>
          <ul className="bottomUl">
            <li>
              Päivän liha/kalalounas (sis. keitto, salaattipöytä, leivät
              kahvi/tee) 10,70 €
            </li>
            <li>
              Päivän kasvislounas ilman keittoa (sis. salaattipöytä, leivät
              kahvi/tee) 10,00 €
            </li>
            <li>
              Päivän keitto (sis. salaattipöytä, leivät, kahvi/tee) 9,50 €
            </li>
          </ul>
          <p>MYÖS TAKE AWAY!</p>
          <div className="border-b-2 mb-10 " /> */}
          {/* <h2 className="text-center ml-10 mr-10">LOUNASKAHVILA RAIKU</h2>
          <p className="text-center">
            Hämeentie 1<br />
            Hakaniemen kauppahallin vieressä
            <br />
            00530 Helsinki
            <br />
            <br /> */}
            {/* <em>
              Meillä voit maksaa myös lounaskortilla ja smartumin maksukortilla
            </em> */}
          {/* </p>
          <p className="text-center">
            {/* <span>
              <em>
                <strong>Avoinna: arkisin klo 11-15.00</strong>
              </em>
            </span> */}
            {/* <br />
            <br /> */}
            {/* Sähköposti:{" "} */}
            {/* <a className="text-blue" href="mailto:raiku@raiku.net">
              raiku (at) raiku.net
            </a>
            <br />
            Puhelin: 044 - 3232515
          </p> */}
          <div className="border-b-2 mb-10 " />

          <footer className="text-center text-xs pb-5">
            © {new Date().getFullYear()},{` `}
            <Link to="/">RAIKU</Link>
          </footer>
        </div>
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
