import React from "react"

import Layout from "./layout"

const IndexPageLayout = ({ data, ...rest }) => {
  console.log("rest:", rest)

  return (
    <Layout>
      <div
        dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
        className="content"
      />
    </Layout>
  )
}

export default IndexPageLayout
