import PropTypes from "prop-types"
import React from "react"

import logo from "../images/raiku.svg"
import fbImg from "../images/fb.png"

const Header = ({ siteTitle }) => (
  <header>
    <img src={logo} alt="Raiku" className="logo" style={{ maxWidth: 180 }} />
    <h2 className="m-auto text-center text-sm -mt-12 mb-10 border-b-2 pb-10">
      <strong>Tällä viikolla </strong>
    </h2>
    <a
      href="https://www.facebook.com/lounaskahvilaRaiku/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={fbImg} alt="facebook" />
    </a>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
