import React from "react"
import { graphql } from "gatsby"

import IndexPageLayout from "../components/indexPageLayout"

const IndexPage = props => {
  return <IndexPageLayout {...props} />
}

IndexPage.propTypes = {}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(fields: { slug: { eq: "/" } }) {
      id
      html
      frontmatter {
        title
      }
    }
  }
`
